import { yearCurrent } from '../helper';
import { fbRef } from '../firebase';
import {
  format,
  isWithinInterval,
  isAfter,
  isBefore,
  addMonths,
} from 'date-fns';
import { formatPeriodToDate } from '../helper';
import { Filter, Filters } from '../../components/Dashboard/YearSelect';

const fbDashboards = fbRef.child('dashboards');

export type UnitsVersions = 'Versão 1' | 'Versão 2';
export const DEFAULT_UNITS_VERSION: UnitsVersions = 'Versão 2';

const unitsEconomicsVersionRef: Record<
  UnitsVersions,
  firebase.default.database.Reference
> = {
  'Versão 1': fbDashboards.child('unitsEconomics'),
  'Versão 2': fbDashboards.child('saasMetrics'),
};

let fbUnitsEconomics = unitsEconomicsVersionRef[DEFAULT_UNITS_VERSION];

/**
 * Função auxiliar para realizar a mudança de referência
 * @param version Valor da versão que deverá ser utilizada.
 */
export const changeUnitsVersion = (version: UnitsVersions) => {
  let newRef = unitsEconomicsVersionRef[version];
  fbUnitsEconomics = newRef;
};

/**
 * Recebe o valor do mês e retorna o quarter formatado
 * ou false se o quarter não é possível. Ex.: "Q1"
 * @param month Mês em string ou number
 */
export const getQuarter = (month: string | number) => {
  // Possíveis valores para o quarter
  const quarterPossibleMonths = [
    [1, 2, 3, '01', '02', '03', '1', '2', '3'], // Q1
    [4, 5, 6, '04', '05', '06', '4', '5', '6'], // Q2
    [7, 8, 9, '07', '08', '09', '7', '8', '9'], // Q3
    [10, 11, 12, '10', '11', '12', '10', '11', '12'], // Q4
  ];

  // Se não mudar o valor, o quarter é o 4
  let qNumber = 4;
  for (let i = 0; i < 4; i++) {
    quarterPossibleMonths[i].includes(month) && (qNumber = i + 1);
  }

  return 'Q' + qNumber;
};

/**
 * objeto do planos golds
 */
interface IPlanGolds {
  starter: number;
  light: number;
  basic: number;
  proGold1: number;
  proGold2: number;
  proGold3: number;
  proEspecial1: number;
  proEspecial2: number;
  proEspecial3: number;
  especial1: number;
  especial2: number;
  especial3: number;
  gold1: number;
  gold2: number;
  gold3: number;
}

/**
 * objeto do unitsEconomics
 */
interface IUnitsEconomics {
  active: IPlanGolds;
  averageMonthlyTicket: string;
  cac: number | undefined;
  newSignatures: number;
  commercialCost: number;
  currentSignatures: IPlanGolds;
  churns: IChurns;
  consumed: string;
  customerLifetime: number;
  ltv: string;
  mrr: string;
}

interface IUnitsEconomicsValues {
  allCurrentSignatures: number;
  averageMonthlyTicket: number;
  cac: number | undefined;
  churnCustomer: number;
  churnRevenue: number;
  customerLifetime: number;
  especial1: number;
  especial2: number;
  especial3: number;
  gold1: number;
  gold2: number;
  gold3: number;
  ltv: number;
  month?: string;
  mrr: number;
  year: string;
  quarter?: string;
}

/**
 * objeto das metricas dos churns
 */
interface IChurns {
  churnCustomer: number;
  churnRevenue: number;
  sumPriceSuspended: number;
  sumPriceTicket: number;
}

/**
 * retorna uma lista de anos
 * @param type
 */
export const getYear = async (type: string) => {
  const snap = await fbDashboards.child(type).once('value');
  const dataDashboard = (snap.val() || {}) as object;
  return dataDashboard; // as chaves são os anos ex.: [2017,2018,2019]
};

/**
 * coleta os dados da coleção unitsEconomics no firebase para adicionar em um objeto no
 * formato que front end espera :P
 * @param selectedYear
 */
export const getDataDashboard = async (selectedYear?: number) => {
  const year = selectedYear || yearCurrent();
  const fbYear = fbUnitsEconomics.child(year.toString());
  const snap = await fbYear.once('value');
  const dataGraphics: Record<string, IUnitsEconomics> = snap.val() || {};
  let keysDataGraphics = Object.keys(dataGraphics);
  keysDataGraphics = keysDataGraphics.sort();

  let dataPreviousMonth: any = new Object();
  const dataMonths: any = [];
  let lastMonth = 0;
  let sumChurnCustomerYearly = 0;
  let sumChurnRevenueYearly = 0;
  let hasCac = true;
  const indexMonthsPrevious: any = [];

  keysDataGraphics.map((key: string) => {
    const month = +key;
    const dataPrevious = dataGraphics[key];

    sumChurnCustomerYearly += dataPrevious.churns.churnCustomer;
    sumChurnRevenueYearly += dataPrevious.churns.churnRevenue;
    lastMonth = +keysDataGraphics[keysDataGraphics.length - 1];

    let cac = dataPrevious.cac;

    if (!cac || cac == 0) {
      hasCac = false;
      cac = undefined;
    }

    dataPreviousMonth = {
      allCurrentSignatures:
        dataPrevious.currentSignatures.gold1 +
        dataPrevious.currentSignatures.gold2 +
        dataPrevious.currentSignatures.gold3 +
        dataPrevious.currentSignatures.especial1 +
        dataPrevious.currentSignatures.especial2 +
        dataPrevious.currentSignatures.especial3,
      averageMonthlyTicket: dataPrevious.averageMonthlyTicket,
      cac,
      churnCustomer: dataPrevious.churns.churnCustomer,
      churnRevenue: dataPrevious.churns.churnRevenue,
      customerLifetime: dataPrevious.customerLifetime,
      especial1: dataPrevious.currentSignatures.especial1,
      especial2: dataPrevious.currentSignatures.especial2,
      especial3: dataPrevious.currentSignatures.especial3,
      gold1: dataPrevious.currentSignatures.gold1,
      gold2: dataPrevious.currentSignatures.gold2,
      gold3: dataPrevious.currentSignatures.gold3,
      ltv: parseFloat(dataPrevious.ltv),
      month: key,
      mrr: parseFloat(dataPrevious.mrr),
      year,
    };  

    dataMonths.push(dataPreviousMonth);
    indexMonthsPrevious.push(month);
  });

  // cálculando o churn anual
  let churnCustomerYearly: number | string =
    sumChurnCustomerYearly / lastMonth || 0;
  churnCustomerYearly =
    parseFloat(churnCustomerYearly.toFixed(2)).toLocaleString('pt-br') + '%';
  let churnRevenueYearly: number | string =
    sumChurnRevenueYearly / lastMonth || 0;
  churnRevenueYearly =
    parseFloat(churnRevenueYearly.toFixed(2)).toLocaleString('pt-br') + '%';

  return {
    churnCustomerYearly,
    churnRevenueYearly,
    data: dataMonths,
    indexPrevious: indexMonthsPrevious,
    hasCac,
  };
};

// TODO: Revisar (unitsEconomics => saasMetrics)
/**
 * atualiza o ano
 * @param year
 * @param type
 */
export const yearUpdates: any = (year?: number, type?: string) => {
  const selectedYear = year || yearCurrent();
  if (type === 'saasMetrics') getDataDashboard(selectedYear);
};

/**
 * Filtra os dados quando o tipo de filtro for mensal
 * @param initialPeriod Data do período inicial
 * @param finalPeriod Data do período final
 */
const monthFilter = async (initialPeriod: Date, finalPeriod: Date) => {
  /** Organiza variáveis que serão manipuladas durante a captura e organização dos dados */
  const initialPeriodYear = format(initialPeriod, 'yyyy');
  const finalPeriodYear = format(finalPeriod, 'yyyy');

  const data = (await fbUnitsEconomics.once('value')).val();
  const dataMonths: IUnitsEconomicsValues[] = [];
  const indexMonthsPrevious: string[] = [];
  let sumChurnCustomerYearly = 0;
  let sumChurnRevenueYearly = 0;
  let hasCac = true;

  /** Percorre cada ano dos dados do Units Economics */
  Object.entries(data).forEach(dataByYear => {
    const dataYear = dataByYear[0];
    const dataByYearValues: any = dataByYear[1];

    /** Verifica os dados que estão entre o ano do período inicial e o ano do período final */
    if (dataYear >= initialPeriodYear && dataYear <= finalPeriodYear) {
      Object.entries(dataByYearValues).forEach(dataByMonth => {
        const dataMonth = dataByMonth[0];
        const dataByMonthValues: any = dataByMonth[1];
        const dataDate = formatPeriodToDate(dataYear + dataMonth + '01');

        /**
         * Verifica se as datas com a referência de ano e mês estão dentro do período solicitado
         * e aplica os cálculos e organização dos dados.
         */
        if (
          isWithinInterval(dataDate, {
            start: initialPeriod,
            end: finalPeriod,
          })
        ) {
          const {
            customerLifetime,
            ltv,
            mrr,
            averageMonthlyTicket,
            cac,
          } = dataByMonthValues;

          const { churnCustomer, churnRevenue } = dataByMonthValues.churns;

          const {
            especial1,
            especial2,
            especial3,
            gold1,
            gold2,
            gold3,
          } = dataByMonthValues.currentSignatures;

          let cacValue: number | undefined = parseFloat(cac?.toFixed(2));
          if (!cac || cac == 0) {
            hasCac = false;
            cacValue = undefined;
          }

          const chartsData: IUnitsEconomicsValues = {
            allCurrentSignatures:
              especial1 + especial2 + especial3 + gold1 + gold2 + gold3,
            averageMonthlyTicket,
            cac: cacValue,
            churnCustomer,
            churnRevenue,
            customerLifetime,
            especial1,
            especial2,
            especial3,
            gold1,
            gold2,
            gold3,
            ltv: parseFloat(ltv),
            month: dataMonth,
            mrr: parseFloat(mrr),
            year: dataYear,
          };

          dataMonths.push(chartsData);
          indexMonthsPrevious.push(dataMonth);
          sumChurnCustomerYearly += churnCustomer;
          sumChurnRevenueYearly += churnRevenue;
        }
      });
    }
  });

  /** Calcula os churns anuais */
  let churnCustomerYearly: number | string =
    sumChurnCustomerYearly / dataMonths.length;
  churnCustomerYearly = `${churnCustomerYearly.toFixed(2)}%`;
  let churnRevenueYearly: number | string =
    sumChurnRevenueYearly / dataMonths.length;
  churnRevenueYearly = `${churnRevenueYearly.toFixed(2)}%`;

  /** Ordena os dados baseado na data */
  const formattedDataMonths = dataMonths.sort((a, b) => {
    const dateA = new Date(`${a.year}-${a.month}-01`);
    const dateB = new Date(`${b.year}-${b.month}-01`);

    if (isAfter(dateA, dateB)) return 1;
    if (isBefore(dateA, dateB)) return -1;

    return 0;
  });

  return {
    churnCustomerYearly,
    churnRevenueYearly,
    data: formattedDataMonths,
    indexPrevious: indexMonthsPrevious,
    hasCac,
  };
};

/**
 * Filtra os dados quando o tipo de filtro for anual ou trimestral
 * @param initialPeriod Data do período iniical
 * @param finalPeriod Data do período final
 * @param filter Nome do tipo de filtro
 */
const yearAndQuarterFilter = async (
  initialPeriod: Date,
  finalPeriod: Date,
  filter: Filter
) => {
  /** Organiza variáveis que serão manipuladas durante a captura e organização dos dados */
  const initialYear = format(initialPeriod, 'yyyy');
  const initialMonth = format(initialPeriod, 'MM');
  const finalYear = format(finalPeriod, 'yyyy');
  const initialYearNumber = parseInt(initialYear);
  const finalYearNumber = parseInt(finalYear);

  const quarterly = filter == Filters.quarter;
  const qoqly = filter.includes(Filters.qoq);

  // Cria um array com todos os anos que serão percorridos
  const years: string[] = [];
  let yearsLen = 0;
  for (let i = initialYearNumber; i <= finalYearNumber; i++) {
    years.push(i.toString());
    yearsLen++;
  }

  const data = (await fbUnitsEconomics.once('value')).val();

  // Variáveis para cálculos e captura dos dados
  // de todos os anos percorridos
  const finalData: IUnitsEconomicsValues[] = [];
  let allSignatures: number[];
  let churnCustomerAllPeriod = 0;
  let churnRevenueAllPeriod = 0;
  let chartsData: IUnitsEconomicsValues;
  let hasCac = true;

  // Percorre todos os anos
  Object.entries(data).map(dataByYear => {
    const yearName = dataByYear[0];
    const yearData: any = dataByYear[1];

    // Garante que só rodará os anos selecionados no filtro
    if (!years.includes(yearName)) return;

    // Variáveis para cálculos ou lógica do ano percorrido
    let Ticket = 0,
      mrrOrArr = 0,
      Ltv = 0,
      sumChurnCustomer = 0,
      sumChurnRevenue = 0,
      CustomerLifetime = 0,
      monthIterator = 0, // Será a quant. de meses percorridos
      initialMonthArrived = false,
      finalMonthArrived = false,
      qoqChurnCustomerSum = 0,
      qoqChurnRevenueSum = 0,
      commercialCostSum = 0, // Soma do custo de MKT e Vendas
      newSignaturesSum = 0; // Soma de  novas assinaturas

    // Percore todos os meses
    Object.entries(yearData)
      .sort()
      .map(dataByMonth => {
        const monthName = dataByMonth[0];
        const monthNameNumber = parseInt(monthName);
        const monthData: any = dataByMonth[1];

        // QOQ: Garante que o primeiro mês a ser considerado será o mês inicial selecionado
        if (qoqly && !initialMonthArrived && monthName === initialMonth) {
          initialMonthArrived = true;
        }

        // QOQ: Condições para que o dado não seja considerado no filtro
        if ((qoqly && !initialMonthArrived) || finalMonthArrived) return;

        // Trimestral: Garante que o primeiro mês a ser considerado será o mês inicial selecionado
        if (quarterly && !initialMonthArrived && monthName === initialMonth) {
          initialMonthArrived = true;
        }

        // Trimestral: Condições para que o dado não seja considerado no filtro
        if (
          (quarterly &&
            !initialMonthArrived &&
            monthName !== initialMonth &&
            yearName === initialYear) ||
          finalMonthArrived
        )
          return;

        // Se já percorreu 3 meses e o filtro é do tipo QOQ
        if (monthIterator === 3 && qoqly) return;

        // Se for Trimestral, muda variável para sabermos que
        // o mês final do último quarter chegou
        if (
          quarterly &&
          monthNameNumber === addMonths(finalPeriod, 2).getMonth() + 1 &&
          yearName === finalYear
        ) {
          finalMonthArrived = true;
        }

        const {
          customerLifetime,
          ltv,
          mrr,
          averageMonthlyTicket,
          commercialCost,
          newSignatures,
        } = monthData;

        // Variáveis de cálculo do CAC
        commercialCostSum += commercialCost;
        newSignaturesSum += newSignatures;

        // Passa os dados do firebase para variáveis
        Ticket = parseFloat(averageMonthlyTicket);
        Ltv = parseFloat(ltv);
        CustomerLifetime = parseFloat(customerLifetime);

        // Anual: Calcula ARR
        mrrOrArr = parseFloat(mrr) * 12;
        // Trimestral: Pega o último MRR do período
        if (qoqly || quarterly) mrrOrArr = parseFloat(mrr);

        const { churnCustomer, churnRevenue } = monthData.churns;

        // Soma churn para fazer média desse ano
        sumChurnCustomer += churnCustomer;
        sumChurnRevenue += churnRevenue;

        // Faz a média de churn do quarter do QOQ
        qoqChurnCustomerSum += churnRevenue;
        qoqChurnRevenueSum += churnCustomer;

        const {
          especial1,
          especial2,
          especial3,
          gold1,
          gold2,
          gold3,
        } = monthData.currentSignatures;

        // Coloca as assinaturas em um array para capturar após
        allSignatures = [especial1, especial2, especial3, gold1, gold2, gold3];

        // Adiciona o quarter nos dados se o filtro for Trimestral
        if (quarterly && monthIterator % 3 === 2) {
          let cacValue: number | undefined = parseFloat(
            (commercialCostSum / newSignaturesSum).toFixed(2)
          );
          if (!commercialCostSum || !newSignaturesSum) {
            hasCac = false;
            cacValue = undefined;
          }

          chartsData = {
            allCurrentSignatures:
              especial1 + especial2 + especial3 + gold1 + gold2 + gold3,
            averageMonthlyTicket: parseFloat(Ticket.toFixed(2)),
            churnCustomer: parseFloat((qoqChurnCustomerSum / 3).toFixed(2)),
            churnRevenue: parseFloat((qoqChurnRevenueSum / 3).toFixed(2)),
            customerLifetime: CustomerLifetime,
            especial1,
            especial2,
            especial3,
            gold1,
            gold2,
            gold3,
            ltv: Ltv,
            mrr: mrrOrArr,
            year: yearName,
            cac: cacValue,
            quarter: getQuarter(monthName),
          };

          commercialCostSum = 0;
          newSignaturesSum = 0;

          finalData.push(chartsData);
        }

        monthIterator++;
      });

    const [
      especial1,
      especial2,
      especial3,
      gold1,
      gold2,
      gold3,
    ] = allSignatures;

    // Calcula a média do churn daquele ano específico
    const churnCustomer = sumChurnCustomer / monthIterator;
    const churnRevenue = sumChurnRevenue / monthIterator;

    // Soma valores para depois calcular média de todos os anos
    churnCustomerAllPeriod += churnCustomer;
    churnRevenueAllPeriod += churnRevenue;

    if (!quarterly) {
      let cacValue: number | undefined = parseFloat(
        (commercialCostSum / newSignaturesSum).toFixed(2)
      );
      if (!commercialCostSum || !newSignaturesSum) {
        hasCac = false;
        cacValue = undefined;
      }

      chartsData = {
        allCurrentSignatures:
          especial1 + especial2 + especial3 + gold1 + gold2 + gold3,
        averageMonthlyTicket: parseFloat(Ticket.toFixed(2)),
        cac: cacValue,
        churnCustomer: parseFloat(churnCustomer.toFixed(2)),
        churnRevenue: parseFloat(churnRevenue.toFixed(2)),
        customerLifetime: CustomerLifetime,
        especial1,
        especial2,
        especial3,
        gold1,
        gold2,
        gold3,
        ltv: Ltv,
        mrr: mrrOrArr,
        year: yearName,
      };
    }

    // Adicionar nome do quarter. Ex: 'Q1'
    if (qoqly) {
      chartsData = {
        ...chartsData,
        quarter: getQuarter(initialMonth) as string,
      };
    }
    !quarterly && finalData.push(chartsData);
  });

  // Ordena os dados baseado na data
  const formattedFinalData = finalData.sort((a, b) => {
    const dateA = new Date(`${a.year}-01-01`);
    const dateB = new Date(`${b.year}-01-01`);

    if (isAfter(dateA, dateB)) return 1;
    if (isBefore(dateA, dateB)) return -1;

    return 0;
  });

  // Calcula a média do churn de todos os anos
  churnCustomerAllPeriod = churnCustomerAllPeriod / yearsLen;
  churnRevenueAllPeriod = churnRevenueAllPeriod / yearsLen;

  // Formata o churn no padrão 'X.XX%'
  const churnCustomerFormatted = `${parseFloat(
    churnCustomerAllPeriod.toFixed(2)
  )}%`;
  const churnRevenueFormatted = `${parseFloat(
    churnRevenueAllPeriod.toFixed(2)
  )}%`;

  return {
    churnCustomerYearly: churnCustomerFormatted,
    churnRevenueYearly: churnRevenueFormatted,
    data: formattedFinalData,
    indexPrevious: years,
    hasCac,
  };
};

/**
 * Função responsável por filtrar os dados do Units Economics de acordo com os dados de período
 * inicial e período final inseridos.
 * @param initialPeriod período inicial do filtro
 * @param finalPeriod período final do filtro
 */
export const getFilteredData = async (
  initialPeriod: Date,
  finalPeriod: Date,
  filter: Filter
) => {
  try {
    if (!initialPeriod) throw new Error('O período inicial não foi informado.');
    if (!finalPeriod) throw new Error('O período final não foi informado.');
    if (filter === Filters.month)
      return await monthFilter(initialPeriod, finalPeriod);
    else return await yearAndQuarterFilter(initialPeriod, finalPeriod, filter);
  } catch (error) {
    throw error;
  }
};
