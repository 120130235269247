import { Filter, Filters } from '../../../../components/Dashboard/YearSelect';
import { coinPtbr, colorBar, colors } from '../../../../services/helper';

export const titleGraphic = (yearly?: boolean) => {
  return [
    'Assinaturas Vigentes',
    'Assinaturas Vigentes (Teste Self Service - Simples)',
    'Assinaturas Vigentes (Teste Self Service - Pro)',
    'LTV',
    yearly ? 'ARR' : 'MRR',
    'Customer Lifetime',
    'Ticket Médio Mensal',
    'Churn',
    'CAC',
    'LTV/CAC Ratio',
    'CAC Payback',
  ];
};

const months: any = {
  '01': 'Janeiro',
  '02': 'Fevereiro',
  '03': 'Março',
  '04': 'Abril',
  '05': 'Maio',
  '06': 'Junho',
  '07': 'Julho',
  '08': 'Agosto',
  '09': 'Setembro',
  '10': 'Outubro',
  '11': 'Novembro',
  '12': 'Dezembro',
};

const isMonthly = (filter: Filter | undefined) =>
  filter === Filters.month || !filter;
const isYearly = (filter: Filter | undefined) => filter === Filters.year;

const isQuarterly = (filter: Filter | undefined) =>
  !filter || filter.includes(Filters.quarter);

const yearOrQuarter = (filter: Filter | undefined) =>
  isYearly(filter) || isQuarterly(filter);

/**
 * Transforma um ano ABCD em /CD. Ex.: 2021 -> /21
 * @param year Ano em string
 */
const getMiniYear = (year: string) => {
  return '/' + year.slice(-2);
};

const addSpecificData = (item: any, filter: Filter | undefined, data: any) => {
  // Se for Mensal
  if (months[item.month] && isMonthly(filter)) {
    return {
      ...data,
      label: months[item.month],
      name: months[item.month].slice(0, 3),
    };
  }

  // Se for Anual ou Trimestral
  else if (yearOrQuarter(filter)) {
    const miniYear = getMiniYear(item.year);
    let quarter;

    // Quarter será o "Q1", "Q2"...
    if (isQuarterly(filter)) {
      quarter = item.quarter + miniYear;
    }

    return {
      ...data,
      label: quarter || '',
      name: quarter || item.year,
    };
  }

  return undefined;
};

/**
 * coletando os dados para o gráfico de assinaturas ativas
 * @param data
 */
export const graphicSubscriptionsActives = (
  data: any,
  filter?: Filter,
  selfServiceType?: "pro" | "basic"
) => {
  let golds: any;
  const planGoldsActives: any = [];
  data.map((item: any) => {
    const basicSelfServiceText = ['Starter', 'Light', 'Basic'];

    const proSelfServiceText = [
      'ProGold1',
      'ProGold2',
      'ProGold3',
      'ProEspecial1',
      'ProEspecial2',
      'ProEspecial3',
    ];

    const defaultText = [
      'Gold1',
      'Gold2',
      'Gold3',
      'Esp1',
      'Esp2',
      'Esp3',
    ];

    const basicSelfServiceGolds: any = {text: basicSelfServiceText};
    const proSelfServiceGolds: any = {text: proSelfServiceText};
    const defaultGolds: any = {text: defaultText};

    [...basicSelfServiceText, ...proSelfServiceText, ...defaultText].map(plan => {
      let planValue = +item[plan.toLocaleLowerCase()];
      planValue = Number.isNaN(planValue) ? 0 : planValue;
      
      if(basicSelfServiceText.includes(plan))
        basicSelfServiceGolds[plan] = planValue;
      else if(proSelfServiceText.includes(plan))
        proSelfServiceGolds[plan] = planValue;
      else if(defaultText.includes(plan))
        defaultGolds[plan] = planValue;
    });

    golds = {
      ...(selfServiceType === 'basic' ?
        basicSelfServiceGolds :
        selfServiceType === 'pro' ? 
        proSelfServiceGolds :
        defaultGolds
      ),
      color: colorBar,
      totalCurrentSignatures: +item.allCurrentSignatures,
      year: item.year,
      description: 'Quantidade de Assinaturas Vigentes no período',
    };

    golds = addSpecificData(item, filter, golds);
    golds && planGoldsActives.push(golds);
  });

  return planGoldsActives;
};

/**
 * coletando os dados para o gráfico MRR
 * @param data
 */
export const graphicMrr = (data: any, filter?: Filter) => {
  const mrr: any = [];
  let valueMrr: any;
  data.map((item: any) => {
    valueMrr = {
      MRR: coinPtbr(item.mrr),
      color: [colors.indigo],
      symbolLeft: 'R$',
      text: ['MRR'],
      year: item.year,
      description: 'Valor da Receita Recorrente Mensal',
    };

    if (isYearly(filter)) {
      // Divide o valor por mil para que o valor
      // não ultrapasse o limite do recharts
      const divided = item.mrr / 1000;

      valueMrr = {
        ...valueMrr,
        ARR: divided,
        text: ['ARR'],
        // Passa o valor normal que aparecerá no tooltip
        formatted: coinPtbr(item.mrr),
        description: 'Valor da Receita Recorrenta Anual',
      };
    }

    valueMrr = addSpecificData(item, filter, valueMrr);
    valueMrr && mrr.push(valueMrr);
  });

  return mrr;
};

/**
 * coletando os dados para o gráfico Ticket Médio Mensal
 * @param data
 */
export const graphicAverageMonthlyTicket = (data: any, filter?: Filter) => {
  const monthlyAverageTicket: any = [];
  let valueTicketmonth: any;
  data.map((item: any) => {
    valueTicketmonth = {
      TMM: Math.round(item.averageMonthlyTicket),
      color: [colors.lightblue],
      symbolLeft: 'R$',
      text: ['TMM'],
      year: item.year,
      description:
        'O valor médio de receita de cada assinatura vigente | Cálculo: MRR / Assinaturas Vigentes',
    };

    valueTicketmonth = addSpecificData(item, filter, valueTicketmonth);
    valueTicketmonth && monthlyAverageTicket.push(valueTicketmonth);
  });

  return monthlyAverageTicket;
};

/**
 * coletando os dados para o gráfico LTV
 * @param data
 */
export const graphicLtv = (data: any, filter?: Filter) => {
  const ltv: any = [];
  let valueLtv: any;
  data.map((item: any) => {
    valueLtv = {
      LTV: coinPtbr(item.ltv),
      color: [colors.orange],
      symbolLeft: 'R$',
      text: ['LTV'],
      year: item.year,
      description:
        'Quanto cada assinatura tende a trazer de receita ao longo da vida dela na Hand Talk | Cálculo: Customer Lifetime X Ticket Médio Mensal',
    };

    valueLtv = addSpecificData(item, filter, valueLtv);
    valueLtv && ltv.push(valueLtv);
  });

  return ltv;
};

/**
 * coletando os dados para o gráfico Customer Lifetime
 * @param data
 */
export const graphicLifetime = (data: any, filter?: Filter) => {
  const customerLifetime: any = [];
  let valueCustomerLifetime: any;
  data.map((item: any) => {
    valueCustomerLifetime = {
      CL: coinPtbr(item.customerLifetime),
      color: [colors.yellow],
      symbolRight: 'meses',
      text: ['CL'],
      year: item.year,
      description: 'Tendência de tempo médio de vida de cada assinatura',
    };

    valueCustomerLifetime = addSpecificData(
      item,
      filter,
      valueCustomerLifetime
    );
    valueCustomerLifetime && customerLifetime.push(valueCustomerLifetime);
  });

  return customerLifetime;
};

/**
 * coletando os dados para o gráfico Churn
 * @param data
 */
export const graphicChurn = (data: any, filter?: Filter) => {
  const churn: any = [];
  let valueChurn: any;
  data.map((item: any) => {
    valueChurn = {
      Customer: item.churnCustomer,
      Revenue: item.churnRevenue,
      color: [colors.blueBaby, colors.indigo],
      symbolRight: '%',
      text: ['Customer', 'Revenue'],
      year: item.year,
      description:
        'Perda de assinaturas por mês (em quantidade e em receita) | Cálculo: Núm. de assinaturas canceladas / Núm. de assinaturas vigentes',
    };

    valueChurn = addSpecificData(item, filter, valueChurn);
    valueChurn && churn.push(valueChurn);
  });

  return churn;
};

/**
 * coletando os dados para o gráfico CAC
 * @param data
 */
export const graphicCac = (data: any, filter?: Filter) => {
  const cac: any = [];
  let valueCac: any;
  data.map((item: any) => {
    valueCac = {
      CAC: item.cac ? coinPtbr(item.cac) : null,
      color: [colors.yellowLight],
      symbolLeft: 'R$',
      text: ['CAC'],
      year: item.year,
      description:
        'Custo para aquisição de clientes | Cálculo: Custo com MKT e Vendas / Novas Assinaturas no período',
    };

    valueCac = addSpecificData(item, filter, valueCac);
    valueCac && cac.push(valueCac);
  });
  return cac;
};

/**
 * coletando os dados para o gráfico LTV/CAC Ratio
 * @param data
 */
export const graphicLtvCac = (data: any, filter?: Filter) => {
  const ltvCac: any = [];
  let valueLtvCac: any;
  data.map((item: any) => {
    const calcValue = item.cac ? item.ltv / item.cac : undefined;

    // Condiciona a cor da barra de acordo com o valor
    let color = [colors.green];
    let legendColor = 'Ótimo';
    if (calcValue && calcValue < 3) {
      color = [colors.red];
      legendColor = 'Ruim';
    } else if (calcValue && calcValue < 5) {
      color = [colors.yellow];
      legendColor = 'Bom';
    }

    valueLtvCac = {
      'LTV/CAC': calcValue?.toFixed(1),
      color,
      legendColor,
      text: ['LTV/CAC'],
      year: item.year,
      description: 'Cálculo: LTV / CAC',
    };

    valueLtvCac = addSpecificData(item, filter, valueLtvCac);
    valueLtvCac && ltvCac.push(valueLtvCac);
  });
  return ltvCac;
};
/**
 * coletando os dados para o gráfico de CAC Payback
 * @param data
 */
export const graphicCacRecover = (data: any, filter?: Filter) => {
  const cacRecover: any = [];
  let valueCacRecover: any;
  data.map((item: any) => {
    const calcValue = item.cac
      ? item.cac / item.averageMonthlyTicket
      : undefined;

    // Condiciona a cor da barra de acordo com o valor
    let color = [colors.green];
    let legendColor = 'Ótimo';
    if (calcValue && calcValue > 12) {
      color = [colors.red];
      legendColor = 'Ruim';
    } else if (calcValue && calcValue > 6) {
      color = [colors.yellow];
      legendColor = 'Bom';
    }

    valueCacRecover = {
      'CAC Payback': calcValue?.toFixed(1),
      color,
      legendColor,
      text: ['CAC Payback'],
      year: item.year,
      description:
        'Quantidade de meses para recuperar o Custo de Aquisição de Cliente | Cálculo: CAC / Ticket Médio Mensal',
    };

    valueCacRecover = addSpecificData(item, filter, valueCacRecover);
    valueCacRecover && cacRecover.push(valueCacRecover);
  });
  return cacRecover;
};

/**
 * coletando os dados para o gráfico de amostra
 * @param data
 */
export const graphicSample = (data: any) => {
  const sample: any = [];
  let valueSample: any;
  data.map((item: any) => {
    valueSample = {
      color: ['transparent'],
      label: '',
      name: '',
      sample: item.value,
      text: [''],
      description: '',
    };
    sample.push(valueSample);
  });

  return sample;
};
