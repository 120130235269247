import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import YearSelect, {
  Filter,
  Filters,
} from '../../../components/Dashboard/YearSelect';
import Layout from '../../../components/Layout';
import { requireAuth } from '../../../services/containers/AuthContainer';
import {
  getDataDashboard,
  getFilteredData,
} from '../../../services/model/UnitsEconomics';
import Bar from './Content/Bar';
import DashboardBlocks from './Content/Blocks';
import { IDashboardBlockProps } from './Content/Blocks/DashboardBlock';
import { cardData } from './Metrics/Blocks';
import {
  graphicAverageMonthlyTicket,
  graphicCac,
  graphicCacRecover,
  graphicLtvCac,
  graphicChurn,
  graphicLifetime,
  graphicLtv,
  graphicMrr,
  graphicSubscriptionsActives,
  titleGraphic,
} from './Metrics/Graphics';
import SampleUnits from './SampleData/UnitsEconomics';
import Dialog from './Dialog/Dialog';
import { subMonths, startOfYear } from 'date-fns';

interface IDialogState {
  open: boolean;
  alerted: boolean;
}

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: auto 400px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 8px;

  @media (max-width: 1050px) {
    grid-template-columns: auto 320px;
  }
`;

const UnitsEconomics = () => {
  const [dataBars, setDataBars] = useState([]);
  const [dataGraphics, setDataGraphics] = useState<any[]>([]);
  const [ready, setReady] = useState<boolean>(false);
  const [dialog, setDialog] = useState<IDialogState>({
    open: false, // Se diálogo está aberto
    alerted: false, // Se já foi alertado nessa sessão
  });

  // limpa os dados
  const cleanData = () => {
    setDataBars([]);
    setDataGraphics([]);
    setReady(false);
  };

  const handleDialogOpen = useCallback(() => {
    !dialog.alerted &&
      setDialog({
        open: true,
        alerted: true,
      });
  }, [dialog.alerted]);

  const handleDialogClose = () => {
    setDialog({
      open: false,
      alerted: true,
    });
  };

  // atualizando os dados para alimentar os gráficos
  const filterDataUnitsEconomics = useCallback(
    async (initialPeriod: Date, finalPeriod: Date, filter: Filter) => {
      // limpa os dados
      let dataCard = {};
      const date = new Date();
      const lastYear = date.getFullYear() - 1;
      const monthOfDecember = 11;

      /** Ativa o loading  */
      cleanData();
      setReady(false);

      const dataUnitsEconomics = await getFilteredData(
        initialPeriod,
        finalPeriod,
        filter
      );

      const hasCac = dataUnitsEconomics.hasCac;
      const dataUnitsEconomicsLast = await getDataDashboard(lastYear);
      const yearly = filter === Filters.year;

      const dataMonthsLength = dataUnitsEconomics.data.length - 1;
      const penultimatePosition = dataUnitsEconomics.data.length - 2;
      const ultimatePosition = dataMonthsLength;
      const dataPenultimate =
        dataMonthsLength === 0
          ? dataUnitsEconomicsLast.data[monthOfDecember]
          : dataUnitsEconomics.data[penultimatePosition];

      const dataUltimate = dataUnitsEconomics.data[ultimatePosition];

      // alimentando os cards, pegando os dados do último mês
      const percentage =
        dataPenultimate.allCurrentSignatures == 0
          ? 100
          : ((dataUltimate.allCurrentSignatures -
              dataPenultimate.allCurrentSignatures) /
              dataPenultimate.allCurrentSignatures) *
            100;

      dataCard = {
        averageMonthlyTicket: dataUltimate.averageMonthlyTicket,
        churnCustomer: dataUnitsEconomics.churnCustomerYearly,
        churnRevenue: dataUnitsEconomics.churnRevenueYearly,
        currentSignatures: dataUltimate.allCurrentSignatures,
        customerLifetime: dataUltimate.customerLifetime,
        ltv: dataUltimate.ltv,
        mrr: dataUltimate.mrr,
        percentageSubscriptions: parseFloat(
          percentage.toFixed(2)
        ).toLocaleString('pt-br'),
        cac: dataUltimate.cac,
      };

      const graphics: any = [];
      graphics.push(
        graphicSubscriptionsActives(dataUnitsEconomics.data, filter),
        graphicSubscriptionsActives(dataUnitsEconomics.data, filter, "basic"),
        graphicSubscriptionsActives(dataUnitsEconomics.data, filter, "pro"),
        graphicLtv(dataUnitsEconomics.data, filter),
        graphicMrr(dataUnitsEconomics.data, filter),
        graphicLifetime(dataUnitsEconomics.data, filter),
        graphicAverageMonthlyTicket(dataUnitsEconomics.data, filter),
        graphicChurn(dataUnitsEconomics.data, filter),
        graphicCac(dataUnitsEconomics.data, filter),
        graphicLtvCac(dataUnitsEconomics.data, filter),
        graphicCacRecover(dataUnitsEconomics.data, filter)
      );

      // alimentando os dados dos gráficos
      const dataGraphics: IDashboardBlockProps[] = graphics.map(
        (graphic: any, index: any) => {
          return {
            color: graphic[0].color,
            data: graphic,
            text: graphic[0].text,
            title: titleGraphic(yearly)[index],
          };
        }
      );

      setReady(true);
      setDataBars(cardData(dataCard));
      setDataGraphics(dataGraphics);
      !hasCac && handleDialogOpen();
    },
    [handleDialogOpen]
  );

  useEffect(() => {
    const runDataUnitsEconomics = async () => {
      const finalPeriod = subMonths(new Date(), 1);
      const initialPeriod = startOfYear(finalPeriod);
      const filter = 'Mensal';

      if (!dialog.alerted)
        await filterDataUnitsEconomics(initialPeriod, finalPeriod, filter);
    };
    runDataUnitsEconomics();
  }, [dialog.alerted, filterDataUnitsEconomics]);

  const toolbar: JSX.Element = (
    <AppBar
      color='default'
      style={{
        background: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        boxShadow: 'none',
        position: 'relative',
      }}
    >
      <Toolbar style={{ padding: 0 }}>
        <Typography variant='body1' color='inherit'>
          <YearSelect
            filterDataUnitsEconomics={filterDataUnitsEconomics}
            name='saasMetrics'
          />
        </Typography>
      </Toolbar>
    </AppBar>
  );

  const memoizedContent = useMemo(
    () => (
      <MainContainer>
        <DashboardBlocks data={dataGraphics} />
        <Bar data={dataBars} ready={ready} />
      </MainContainer>
    ),
    [dataBars, dataGraphics, ready]
  );

  const dialogDescription =
    'Alguns dados relacionados ao CAC não estão disponíveis para esse período, não estranhe \
    caso esse ou algum período esteja sem os dados sobre o CAC durante toda sua sessão.';

  return (
    <Layout toolbar={toolbar} minWidth={724}>
      <ReactPlaceholder ready={ready} customPlaceholder={<SampleUnits />}>
        <Dialog
          open={dialog.open}
          handleClose={handleDialogClose}
          title='Dados do CAC'
          description={dialogDescription}
        />
        {memoizedContent}
      </ReactPlaceholder>
    </Layout>
  );
};

// ERICA Essa definição de redirecionamento é temporário até ir para versão com hooks
const whereReturn = (withAuthProps?: any) => {
  if (!withAuthProps.auth.state.role) return <div />;

  if (withAuthProps.auth.state.role === 'admin') return <UnitsEconomics />;

  return <Redirect to='/' />;
};

export default requireAuth(whereReturn);
